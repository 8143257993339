import React from 'react'

import { useTranslation, Trans } from 'react-i18next'

import { Container } from '@material-ui/core'

import felsineoLogoImage from 'assets/img/felsineo-logo-b.png'

import getCertifications from 'data/certifications'

const CertificationsPage = () => {
  const { t } = useTranslation('certificationPage')
  const certifications = getCertifications()

  return (
    <div id="certification-page">

      <section id="header">
        <div id="logo-circle">
          <div id="circle">
            <img src={felsineoLogoImage} alt="Felsineo FMV" />
          </div>
        </div>
      </section>

      <section className="rounded-page">
        <Container>
          <section id="qualita">
            <h1 className="title-page">{t('quality.title')}</h1>
            <p>{t('quality.text')}</p>

            <article id="youtube-video">
              <iframe width="560" height="315" src="https://www.youtube.com/embed/yyTlSC8E0UI" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
              <div>
                <Trans
                  t={t}
                  i18nKey='quality.youtube'
                  components={{ 1: <span /> }}
                />
              </div>
            </article>
          </section>

          <section id="certificazioni">
            <h1>{t('certifications.title')}</h1>
            <p>{t('certifications.text')}</p>

            <section id="cards">
              {certifications.map(({ id, logo, link }) => (
                <article className="card" key={id}>
                  <div><img src={logo} alt="" /></div>
                  <div><a href={link.url} target="_blank" rel="noreferrer">{link.title}</a></div>
                </article>
              ))}
            </section>

          </section>

        </Container>
      </section>
    </div>
  )
}

export default CertificationsPage
