import React, { useEffect, useRef } from 'react'

import useNavigation from 'hooks/useNavigation'
import useLotData from 'hooks/useLotData'

import Button from '@material-ui/core/IconButton'

import iconProdotto from 'assets/img/menu-icons/prodotto.svg'
import iconAzienda from 'assets/img/menu-icons/azienda.svg'
import iconCertificazioni from 'assets/img/menu-icons/certificazioni.svg'
import iconTracciabilita from 'assets/img/menu-icons/tracciabilita.svg'

import './style.scss'

const MenuButton = ({ onClick, active, icon }) => {
  return (
    <div className={active ? 'item-selected' : ''}>
      <Button
        onClick={onClick}
        onTouchStart={onClick}
      >
        <img src={icon} alt="" />
      </Button>
    </div>
  )
}

const Menu = () => {
  const { sectionActive, changeSection, resetURL } = useNavigation()
  const { lotNotFound, setLotNotFound } = useLotData()

  const menu = useRef(null)

  const hideMenu = () => menu.current.classList.add('menu-hidden')
  const showMenu = () => menu.current.classList.remove('menu-hidden')
  const isVisibleMenu = () => menu.current.classList.contains('menu-hidden')

  const upRef = useRef({
    t0: 0,
    t1: 0,
    timeout: null
  })

  const downRef = useRef({
    t0: 0,
    t1: 0,
    timeout: null
  })

  const lastHeightRef = useRef(window.innerHeight)

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('scroll', handleScroll)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  const handleResize = () => {
    const currHeight = window.innerHeight

    if (isVisibleMenu && currHeight < lastHeightRef.current) {
      hideMenu()
    }

    lastHeightRef.current = currHeight
  }

  const handleScroll = (e) => {
    const UP_TIME = 800
    const DOWN_TIME = 100

    const TRESHOLD = 380

    const up = upRef.current
    const down = downRef.current

    if (!up.timeout) {
      up.t0 = window.scrollY

      up.timeout = setTimeout(() => {
        up.t1 = window.scrollY
        const v = Math.abs(up.t0 - up.t1) / UP_TIME * 1000

        if (up.t1 < up.t0 && v > TRESHOLD) {
          showMenu()
        }

        up.timeout = null
      }, UP_TIME)
    }

    if (!down.timeout) {
      down.t0 = window.scrollY

      down.timeout = setTimeout(() => {
        down.t1 = window.scrollY

        if (down.t0 <= 0 || down.t1 <= 0) {
          showMenu()
        } else if (down.t1 > down.t0) {
          hideMenu()
        }

        down.timeout = null
      }, DOWN_TIME)
    }
  }

  const handleClick = (n) => {
    // Se è attivo il flag lot not found
    // rimuovo /lot/LOT_ID dall'url e ripristino lo stato
    if (lotNotFound) {
      resetURL()
      setLotNotFound(false)
    }
    changeSection(n)
  }

  return (
    <div id="menu" ref={menu}>
      <MenuButton onClick={() => handleClick(1)} active={sectionActive === 1} icon={iconProdotto} />
      <MenuButton onClick={() => handleClick(2)} active={sectionActive === 2} icon={iconAzienda} />
      <MenuButton onClick={() => handleClick(3)} active={sectionActive === 3} icon={iconCertificazioni} />
      <MenuButton onClick={() => handleClick(4)} active={sectionActive === 4} icon={iconTracciabilita} />
    </div>
  )
}

export default Menu
