import React from 'react'
import { useTranslation, Trans } from 'react-i18next'
import { returnAbbr } from 'utils/Utils'
import i18n from 'i18n'
import itLogo1 from 'assets/img/product-page/loghi/it/1.png'
import itLogo2 from 'assets/img/product-page/loghi/it/2.png'
import itLogo3 from 'assets/img/product-page/loghi/it/3.png'
import itLogo4 from 'assets/img/product-page/loghi/it/4.png'
import itLogo5 from 'assets/img/product-page/loghi/it/5.png'
import itLogo6 from 'assets/img/product-page/loghi/it/6.png'

import enLogo1 from 'assets/img/product-page/loghi/en/1.png'
import enLogo2 from 'assets/img/product-page/loghi/en/2.png'
import enLogo3 from 'assets/img/product-page/loghi/en/3.png'
import enLogo4 from 'assets/img/product-page/loghi/en/4.png'
import enLogo5 from 'assets/img/product-page/loghi/en/5.png'
import enLogo6 from 'assets/img/product-page/loghi/en/6.png'

import frLogo1 from 'assets/img/product-page/loghi/fr/1.png'
import frLogo2 from 'assets/img/product-page/loghi/fr/2.png'
import frLogo3 from 'assets/img/product-page/loghi/fr/3.png'
import frLogo4 from 'assets/img/product-page/loghi/fr/4.png'
import frLogo5 from 'assets/img/product-page/loghi/fr/5.png'
import frLogo6 from 'assets/img/product-page/loghi/fr/6.png'

// import productImage from 'assets/img/product-page/product-image.png'
import lievitoImage from 'assets/img/product-page/lievito-image.png'
import circleImage from 'assets/img/product-page/circle-image.png'

import ricettaImg1 from 'assets/img/product-page/ricette/1.png'
import ricettaImg2 from 'assets/img/product-page/ricette/2.png'
import ricettaImg3 from 'assets/img/product-page/ricette/3.png'
import ricettaImg4 from 'assets/img/product-page/ricette/4.png'
import ricettaImg5 from 'assets/img/product-page/ricette/5.png'
import ricettaImg6 from 'assets/img/product-page/ricette/6.png'
import ricettaImg7 from 'assets/img/product-page/ricette/7.png'
import ricettaImg8 from 'assets/img/product-page/ricette/8.png'

import logoVerde1 from 'assets/img/product-page/loghi-verdi/1.svg'
import logoVerde2 from 'assets/img/product-page/loghi-verdi/2.svg'
import logoVerde3 from 'assets/img/product-page/loghi-verdi/3.svg'
import logoVerde4 from 'assets/img/product-page/loghi-verdi/4.svg'

import cereali2 from 'assets/img/product-page/green/cereali/2.png'
import cereali3 from 'assets/img/product-page/green/cereali/3.png'
import cereali4 from 'assets/img/product-page/green/cereali/4.png'
import cereali5 from 'assets/img/product-page/green/cereali/5.png'

import tradizionali1 from 'assets/img/product-page/green/tradizionali/1.png'
import tradizionali2 from 'assets/img/product-page/green/tradizionali/2.png'
import tradizionali3 from 'assets/img/product-page/green/tradizionali/3.png'
import tradizionali4 from 'assets/img/product-page/green/tradizionali/4.png'
import tradizionali5 from 'assets/img/product-page/green/tradizionali/5.png'

const getProduct = () => {
  const { t } = useTranslation('productPage')
  // const [productImage, setProductImage] = useState('')
  const keyLanguage = i18n.language.split('-')[0]

  const toast3tags = keyLanguage === 'it'
    ? {
        1: <a href="http://www.fairtrade.it/marchi/" target='_blank' rel="noreferrer" />
      }
    : {
        1: <a href="https://info.fairtrade.net/sourcing" target='_blank' rel="noreferrer" />
      }

  const lang = returnAbbr(i18n.language)
  const getProductImageLang = (lang) => {
    let productImage = ''
    switch (lang) {
      case 'en': {
        console.log('casistica inglese:::')
        productImage = require('assets/img/product-page/product-image/en/product-image.png').default
        break
      }
      case 'it': {
        console.log('casistica it')
        productImage = require('../assets/img/product-page/product-image/it/product-image.png').default
        break
      }
      case 'fr': {
        console.log('casistica francese:::')
        productImage = require('../assets/img/product-page/product-image/fr/product-image.png').default
        break
      }
      default: {
        console.log('casistica default:::', lang.includes('fr'))
        productImage = require('../assets/img/product-page/product-image/en/product-image.png').default
        break
      }
    }
    return productImage
  }
  // useEffect(() => {
  //   setProductImage(getProductImageLang(i18n.language))
  // }, [i18n])

  return {
    loghi: {
      it: [itLogo1, itLogo2, itLogo3, itLogo4, itLogo5, itLogo6],
      en: [enLogo1, enLogo2, enLogo3, enLogo4, enLogo5, enLogo6],
      fr: [frLogo1, frLogo2, frLogo3, frLogo4, frLogo5, frLogo6]
    },
    toast: [
      [1, t('toasts.1')],
      [2, t('toasts.2')],
      [3, <Trans
            key={3}
            t={t}
            i18nKey='toasts.3'
            components={toast3tags}
          />
      ],
      [4, t('toasts.4')],
      [5, t('toasts.5')],
      [6, <Trans
            key={3}
            t={t}
            i18nKey='toasts.6'
            components={{
              1: <div />,
              2: <div className='center-text' />,
              3: <a href="#" id="link-tracciabilita" />
            }}
          />
      ]
    ],
    image: getProductImageLang(lang),
    lievito: lievitoImage,
    ingredienti: [
      [1, t('accordion.ingredients.data.1')],
      [2, <Trans
            key={2}
            t={t}
            i18nKey='accordion.ingredients.data.2'
            components={{ b: <b /> }}
          />, '31%'],
      [3, <Trans
            key={3}
            t={t}
            i18nKey='accordion.ingredients.data.3'
            components={{ b: <b /> }}
          />, '8%'],
      [4, t('accordion.ingredients.data.4')],
      [5, t('accordion.ingredients.data.5')],
      [7, t('accordion.ingredients.data.7')],
      [8, t('accordion.ingredients.data.8')],
      [9, t('accordion.ingredients.data.9')],
      [10, t('accordion.ingredients.data.10'), '1%'],
      [11, t('accordion.ingredients.data.11'), '0,08%'],
      [12, t('accordion.ingredients.data.12')],
      [13, t('accordion.ingredients.data.13'), '0,04%'],
      [14, t('accordion.ingredients.data.14')]
    ],
    valoriNutrizionali: [
      [1, t('accordion.nutritionalValues.data.1'), '1048kj/249Kcal', '12%'],
      [2, t('accordion.nutritionalValues.data.2'), '7,8g', '11%'],
      [3, t('accordion.nutritionalValues.data.3'), '2,7g', '13%'],
      [4, t('accordion.nutritionalValues.data.4'), '15g', '6%'],
      [5, t('accordion.nutritionalValues.data.5'), '2,8g', '3%'],
      [6, t('accordion.nutritionalValues.data.6'), '1,4g', '-'],
      [7, t('accordion.nutritionalValues.data.7'), '29g', '58%'],
      [8, t('accordion.nutritionalValues.data.8'), '1,5g', '25%']
    ],
    attributes: [
      [1, t('accordion.attributes.data.1.title'), t('accordion.attributes.data.1.value')],
      [2, t('accordion.attributes.data.2.title'), '13'],
      [4, t('accordion.attributes.data.4.title'), '8053853070111'],
      [5, t('accordion.attributes.data.5.title'), t('accordion.attributes.data.5.value')],
      [6, t('accordion.attributes.data.6.title'), t('accordion.attributes.data.6.value')]
    ],
    circle: circleImage,
    ricette: [
      [1, t('recipes.data.1'), ricettaImg1, 'https://www.felsineoveg.com/ricetta/friselle-ghiotte'],
      [2, t('recipes.data.2'), ricettaImg2, 'https://www.felsineoveg.com/ricetta/mezzelune-con-hummus-di-ceci-e-mandorle-tostate'],
      [3, t('recipes.data.3'), ricettaImg3, 'https://www.felsineoveg.com/ricetta/spaghetti-di-patate-con-pane-aromatico'],
      [4, t('recipes.data.4'), ricettaImg4, 'https://www.felsineoveg.com/ricetta/tajine-con-prugne-albicocche-e-mandorle'],
      [5, t('recipes.data.5'), ricettaImg5, 'https://www.felsineoveg.com/ricetta/patate-ripiene'],
      [6, t('recipes.data.6'), ricettaImg6, 'https://www.felsineoveg.com/ricetta/ragu-bianco'],
      [7, t('recipes.data.7'), ricettaImg7, 'https://www.felsineoveg.com/ricetta/ragu-rosso'],
      [8, t('recipes.data.8'), ricettaImg8, 'https://www.felsineoveg.com/ricetta/polpette-vegetali-e-broccoli-con-salsa-bbq-artigianale']
    ],
    loghiVerdi: [
      { title: t('greenLogos.1.title'), subtitle: t('greenLogos.1.subtitle'), img: logoVerde1 },
      { title: t('greenLogos.2.title'), subtitle: t('greenLogos.2.subtitle'), img: logoVerde2 },
      { title: t('greenLogos.3.title'), subtitle: t('greenLogos.3.subtitle'), img: logoVerde3 },
      { title: t('greenLogos.4.title'), subtitle: t('greenLogos.4.subtitle'), img: logoVerde4 }
    ],
    cerealiElegumi: [
      { title: t('green.bio.data.2'), img: cereali2 },
      { title: t('green.bio.data.3'), img: cereali3 },
      { title: t('green.bio.data.4'), img: cereali4 },
      { title: t('green.bio.data.5'), img: cereali5 }
    ],
    tradizionali: [
      { title: t('green.traditional.data.1'), img: tradizionali1 },
      { title: t('green.traditional.data.2'), img: tradizionali2 },
      { title: t('green.traditional.data.3'), img: tradizionali3 },
      { title: t('green.traditional.data.4'), img: tradizionali4 },
      { title: t('green.traditional.data.5'), img: tradizionali5 }
    ]
  }
}

export default getProduct
