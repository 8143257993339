import React, { Fragment, useState, useEffect } from 'react'

import { useTranslation, Trans } from 'react-i18next'
import useNavigation from 'hooks/useNavigation'
import useLotData from 'hooks/useLotData'

import {
  Grid,
  Container,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Snackbar,
  Slide
} from '@material-ui/core'

import { ExpandMore } from '@material-ui/icons'
import logo1 from 'assets/img/product-page/loghi-accordion/1.svg'
import logo2 from 'assets/img/product-page/loghi-accordion/2.svg'
import logo3 from 'assets/img/product-page/loghi-accordion/3.svg'

import RicetteSlider from 'components/RicetteSlider/RicetteSlider'
import FindLot from 'components/FindLot/FindLot'

import felsineoLogo from 'assets/img/felsineo-logo-b.png'
import greenTitle from 'assets/img/product-page/green/title.png'

import getProduct from 'data/product'

const ProductPage = () => {
  const product = getProduct()
  const { t, i18n } = useTranslation('productPage')

  const [expanded, setExpanded] = useState(false)
  const [toastOpen, setToastOpen] = useState()
  const { lotLoaded } = useLotData()
  const { changeSection } = useNavigation()

  const keyLanguage = i18n.language.split('-')[0]

  useEffect(() => {
    if (toastOpen === 'toast6') {
      const el = document.getElementById('link-tracciabilita')
      if (el) {
        el.onclick = (e) => {
          e.preventDefault()
          changeSection(4)
        }
      }
    }
  }, [toastOpen])

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false)
  }

  const handleClose = () => {
    setToastOpen()
  }

  const handleClick = (e) => {
    e.preventDefault()
    setToastOpen(e.target.className)
  }

  return (<>
    {product.toast.map(([id, content]) => {
      const className = 'toast' + id
      return <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        key={id}
        open={toastOpen === className}
        TransitionComponent={Slide}
        onClose={handleClose}
        action={<div>{content}</div>}
      />
    })}

    <div id="product-page">
      <section id="header">
        <article>
          <Container>
            <Grid container alignItems='center' spacing={1} style={{ padding: '10vw 0' }}>
              <Grid item xs={9}>
                <h1 id="product-title">
                  <Trans
                    t={t}
                    i18nKey='productName'
                    components={{ sup: <sup /> }}
                  />
                </h1>
              </Grid>
              <Grid item xs={3}>
                <div id="logo"><img src={felsineoLogo} alt="logo" /></div>
              </Grid>
            </Grid>
          </Container>
        </article>

        <article>
          <Container>
            <Grid container spacing={2}>
              <Grid item xs={6}>
              <Grid container spacing={1}>
              {product.loghi[keyLanguage].map((logo, i) => (
                <Grid item key={logo} xs={6}>
                  <div className="logo">
                    <a href="#" onClick={handleClick}>
                      <img src={logo} className={'toast' + (i + 1)} alt="" />
                    </a>
                  </div>
                </Grid>
              ))}
            </Grid>
              </Grid>
              <Grid item xs={6}>
                <div id="product-image">
                  <span id="circle"></span>
                  <img src={product.image} alt="" />
                </div>
              </Grid>
            </Grid>
          </Container>
        </article>

        <article id="description">
          <Container>
            <p>
              <Trans
                t={t}
                i18nKey='productDescription'
                components={{ color: <span />, sup: <sup /> }}
              />
            </p>
          </Container>
        </article>

      </section>
      <section className="rounded-page">

        {!lotLoaded &&
          <article id="find-lot">
            <Container>
              <FindLot />
            </Container>
          </article>
        }

        <div className="center-text" style={{ margin: '20px 0' }}><img src={product.lievito} style={{ width: '100%' }} alt="" /></div>

        <article id="accordion">
            <Accordion elevation={0} expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel1-content"
                id="panel1-header"
              >
                <Typography><img src={logo1} alt='' />{t('accordion.ingredients.title')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Container>
                  <table id="ingredienti">
                    <tbody>
                    {product.ingredienti.map(([id, name, qt]) => (
                      <tr key={id}>
                        {qt
                          ? <>
                          <td>{name}</td>
                          <td>{qt}</td>
                        </>
                          : <td colSpan={2}>{name}</td>
                        }
                      </tr>
                    ))}
                    </tbody>
                    <tfoot>
                      <tr>
                        <td colSpan="2">
                          <Trans
                            t={t}
                            i18nKey='accordion.ingredients.foot.1'
                            components={{ b: <b /> }}
                          />
                        </td>
                      </tr>
                      <tr>
                        <td colSpan="2">
                        {t('accordion.ingredients.foot.2')}
                        </td>
                      </tr>
                    </tfoot>
                  </table>
                </Container>
              </AccordionDetails>
            </Accordion>
            <Accordion elevation={0} expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel2-content"
                id="panel2-header"
              >
                <Typography><img src={logo2} alt='' />{t('accordion.nutritionalValues.title')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Container>
                  <table id="valori-nutrizionali">
                    <tbody>
                    {product.valoriNutrizionali.map(([id, title, value]) => (
                      <tr key={id}>
                        <td>{title}</td>
                        <td>{value}</td>
                      </tr>
                    ))}
                    </tbody>
                  </table>
                </Container>
              </AccordionDetails>
            </Accordion>
            <Accordion elevation={0} expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
              <AccordionSummary
                expandIcon={<ExpandMore />}
                aria-controls="panel3-content"
                id="panel3-header"
              >
                <Typography><img src={logo3} alt='' />{t('accordion.attributes.title')}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                <Container>
                  <div id="caratteristiche">
                    {product.attributes.map(([id, title, value]) =>
                      <div className='item' key={id}>
                        <div className="title">{title}</div>
                        <div className="value">{value}</div>
                      </div>
                    )}
                  </div>
                </Container>
              </AccordionDetails>
            </Accordion>
        </article>

        <article>
          <Grid container spacing={2} style={{ margin: '30px 0' }} alignItems='center'>
            <Grid item xs={5}>
            <div id="product-image">
              <span className="circle"></span>
              <img src={product.image} alt="" />
            </div>
            </Grid>
            <Grid item xs={7}>
              <div style={{ width: '90%' }}>
                <h3 style={{ margin: '5px 0' }}>
                  <Trans
                    t={t}
                    i18nKey='slogan1.text'
                    components={{ sup: <sup /> }}
                  />
                </h3>
                <small>{t('slogan1.subtitle')}</small>
              </div>
            </Grid>
          </Grid>
        </article>

        <article>
          <Container>
            <h3
              style={{
                marginTop: '80px',
                marginBottom: '60px',
                textAlign: 'center',
                fontSize: '1.3em'
              }}
            >{t('slogan2')}</h3>
          </Container>
        </article>

        <article id="logo-circle">
          <div id="back-circle"></div>
          <div id="front-circle"></div>
          <img src={product.circle} alt="" />
        </article>

        <article id="loghi-verdi">
          <Container>
            <Grid container>
              {product.loghiVerdi.map(({ title, subtitle, img }) =>
                <Fragment key={title}>
                  <Grid item xs={6}>
                    <div className="logo">
                      <img src={img} alt="" />
                      <div className="title">{title}</div>
                      <div className="subtitle">{subtitle}</div>
                    </div>
                  </Grid>
                </Fragment>
              )}
            </Grid>
          </Container>
        </article>

        <article>
          <Container>
            <h4>{t('slogan3')}</h4>
          </Container>
        </article>

        <article id="ricette">
          <Container>
            <h3>{t('recipes.title')}</h3>
          </Container>

          <RicetteSlider items={product.ricette} />
        </article>

        <article id="green-e-green">
          <Container>
            <div id="title"><img src={greenTitle} alt="Green&Green" /></div>
            <h4>{t('green.slogan')}</h4>
          </Container>

            <section>
              <Container>
                <div className="title">{t('green.bio.title')}</div>
                <p>{t('green.bio.text')}</p>
              </Container>
              <ul className="image-slider">
                {product.cerealiElegumi.map(item => (
                  <li key={item.title}>
                    <img src={item.img} alt="" />
                    <p>{item.title}</p>
                  </li>
                ))}
              </ul>
            </section>

            <section>
              <Container>
                <div className="title">{t('green.traditional.title')}</div>
                <p>{t('green.traditional.text')}</p>
              </Container>

              <ul className="image-slider">
                {product.tradizionali.map(item => (
                  <li key={item.title}>
                    <img src={item.img} alt="" />
                    <p>{item.title}</p>
                  </li>
                ))}
              </ul>
            </section>

        </article>

      </section>
    </div>
  </>)
}

export default ProductPage
