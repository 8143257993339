import React, { useState, useEffect } from 'react'

import { useTranslation } from 'react-i18next'

import useNavigation from 'hooks/useNavigation'

import CustomModal from 'components/CustomModal/CustomModal'

import { TextField, InputAdornment } from '@material-ui/core'
import { ThemeProvider, createTheme } from '@material-ui/core/styles'

import Help from '@material-ui/icons/Help'

import findLotImage from 'assets/img/find-lot.png'

import './style.css'

const theme = createTheme({
  palette: {
    primary: {
      light: '#B8BF23',
      main: '#B8BF23',
      dark: '#B8BF23',
      contrastText: '#fff'
    }
  }
})

const isValidLotNumber = str => str.match(/^[0-9A-Za-z]+$/g)

const FindLot = () => {
  const { t } = useTranslation('findLot')

  const { viewTraceability } = useNavigation()

  const [lotNumber, setLotNumber] = useState('')
  const [isOpenModal, setIsOpenModal] = useState(false)

  const [validField, setValidField] = useState(false)
  const [touched, setTouched] = useState(false)

  useEffect(() => {
    setValidField(isValidLotNumber(lotNumber))
  }, [lotNumber])

  const handleChange = (e) => {
    if (!touched) {
      setTouched(true)
    }

    setLotNumber(e.target.value)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    viewTraceability(lotNumber)
  }

  const handleHelpClick = () => setIsOpenModal(true)

  return (
    <>
      <CustomModal
        className='find-lot-dialog'
        isOpen={isOpenModal}
        setIsOpen={setIsOpenModal}
        closeButton
      >
        <img src={findLotImage} />
      </CustomModal>

      <h2 className='center-text primary-color'>{t('text')}</h2>
      <form onSubmit={handleSubmit}>
        <ThemeProvider theme={theme}>
          <TextField
            fullWidth
            label={t('placeholder')}
            onChange={handleChange}
            error={touched && !validField}
            color='primary'
            InputProps={{
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ cursor: 'pointer' }}
                  onClick={handleHelpClick}
                >
                  <Help style={{ color: '#949494' }} />
                </InputAdornment>
              )
            }}/>
          <div style={{
            textAlign: 'center',
            margin: '20px 0'
          }}><button type='submit' className='custom-button' disabled={!validField}>{t('button')}</button></div>
        </ThemeProvider>
      </form>
    </>
  )
}

export default FindLot
