import Axios from 'axios'

import configuration from 'configuration'

const TRUSTY_API = configuration.apiBaseUrl

export const getProduct = async (uuid, fields) => {
  try {
    const API_URL = `${TRUSTY_API}/public/products/${uuid}` + (fields ? `?fields=${fields.join(',')}` : '')

    const res = await Axios.get(API_URL, {
      headers: {
        authorization: `Basic ${configuration.token}`
      }
    })

    return res.data.data
  } catch (err) {
    throw new Error('Impossibile scaricare i dati del prodotto')
  }
}

export const getData = async (productUuid, lotUuid) => {
  try {
    const API_URL = `${TRUSTY_API}/public/products/${productUuid}/lots/${lotUuid}/summary`
    const res = await Axios.get(API_URL, {
      headers: {
        authorization: `Basic ${configuration.token}`
      }
    })

    return res.data.data
  } catch (err) {
    throw new Error('Impossibile scaricare i dati del lotto')
  }
}
