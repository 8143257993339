import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

import LanguageDetector from 'i18next-browser-languagedetector'

// eslint-disable-next-line no-unused-vars
import italianCommon from 'translations/it/common.json'
import englishCommon from './translations/en/common.json'
import frenchCommon from './translations/fr/common.json'

// variaible con le opzioni del LanguageDetector
const languageDetectorOptions = {
  // order and from where user language should be detected
  order: ['navigator', 'localStorage', 'cookie', 'querystring', 'htmlTag', 'path', 'subdomain'],
  // cache user language on
  caches: ['localStorage', 'cookie'],
  // excludeCacheFor: ['cimode'], // languages to not persist (cookie, localStorage)
  // optional expire and domain for set cookie
  cookieMinutes: 10,
  // cookieDomain: 'myDomain',
  // optional htmlTag with lang attribute, the default is:
  htmlTag: document.documentElement

  // only detect languages that are in the whitelist
  // checkWhitelist: true
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: {
      default: ['en']
    },
    // lng: 'en', // ! FOR DEBUG
    debug: false,
    // nonExplicitWhitelist: true, // if true will pass eg. en-US if finding en in whitelist
    detection: languageDetectorOptions, // per passare opzioni del languageDeterctor

    resources: {
      it: italianCommon,
      en: englishCommon,
      fr: frenchCommon
    },

    interpolation: {
      escapeValue: false // not needed for react as it escapes by default
    }
  }, (err) => {
    if (err) {
      console.error('something went wrong loading i18next, err => ', err)
    }
  })

export default i18n
