import React from 'react'

import { useTranslation } from 'react-i18next'

import { Grid, Container } from '@material-ui/core'

import CustomModal from 'components/CustomModal/CustomModal'

import greenLogo from 'assets/img/traceability-page/loghi-modal/green.png'

import lievitoLogoIt from 'assets/img/traceability-page/loghi-modal/it/lievito.png'
import fairtradeLogoIt from 'assets/img/traceability-page/loghi-modal/it/fairtrade.png'

import lievitoLogoEn from 'assets/img/traceability-page/loghi-modal/en/lievito.png'
import fairtradeLogoEn from 'assets/img/traceability-page/loghi-modal/en/fairtrade.png'

import './style.scss'

const DettaglioFaseModal = (props) => {
  const { item, isOpen, setIsOpen } = props

  const { t, i18n } = useTranslation('dettaglioFaseModal')
  const keyLanguage = i18n.language.split('-')[0]

  const isTypeA = item => item.type === 'A'
  const isTypeB = item => item.type === 'B'

  const getABody = item => {
    return (
      <section className="rounded-page">
        <div className="content-a">
          <Container>
            <h1>{item.titolo}</h1>

            <article>
              <p>{item.testo}</p>
            </article>

            <article>
              <Grid className="detail" container>
                <Grid item xs={12}>{t('typeA.ingredients')}</Grid>
                <Grid item xs={12}>{item.ingredienti}</Grid>
                <Grid item xs={12}>{t('typeA.distributor')}</Grid>
                <Grid item xs={12}>{item.distributore}</Grid>
                <Grid item xs={12}>{t('typeA.certifications')}</Grid>
                <Grid item xs={12}>
                  {item.loghi.map(logo => <img src={logo} key={logo} alt="" />)}
                </Grid>
                <Grid item xs={12}>{t('typeA.date')}</Grid>
                <Grid item xs={12}>{item.data}</Grid>
              </Grid>
            </article>
          </Container>
        </div>
      </section>
    )
  }

  const getBBody = item => {
    return (
      <section className="rounded-page">
        <div className="content-b">
          <Container>
            <h1>{item.titolo}</h1>

            <article>
              <div className="center-text">
                <img src={greenLogo} style={{ width: '30vw' }} alt="Good&Green" />
              </div>
            </article>

            <article>
              <Grid container className="detail" alignItems='center' spacing={2}>
                <Grid item xs={8}>{item.testo1}</Grid>
                <Grid item xs={4}>
                  {keyLanguage === 'it'
                    ? <img src={lievitoLogoIt} style={{ width: '25vw' }} />
                    : <img src={lievitoLogoEn} style={{ width: '25vw' }} />
                  }
                </Grid>
                <Grid item xs={8}>{item.testo2}</Grid>
                <Grid item xs={4}>
                  {keyLanguage === 'it'
                    ? <img src={fairtradeLogoIt} style={{ width: '20vw' }} />
                    : <img src={fairtradeLogoEn} style={{ width: '20vw' }} />
                  }
                </Grid>
                <Grid item xs={12}>{item.testo3}</Grid>
              </Grid>
            </article>

          </Container>
        </div>
      </section>
    )
  }

  return (
    <CustomModal
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      className="dettaglio-fase-modal"
      closeButton
    >
      <section id="header">
        <div className="image" style={{ backgroundImage: 'url(' + item.header + ')' }}></div>
      </section>

      {isTypeA(item) && getABody(item)}
      {isTypeB(item) && getBBody(item)}
    </CustomModal>
  )
}

export default DettaglioFaseModal
