import React from 'react'

import logoImg from 'assets/img/trusty-logo-black.svg'

import './style.css'

const SIZES = ['small', 'medium', 'large']
const COLORS = ['dark', 'light']

const LogoLoader = (props) => {
  const { size, color, text } = props

  const loaderSize = SIZES.includes(size) ? size : SIZES[0]
  const loaderColor = COLORS.includes(color) ? color : COLORS[0]

  return (
    <div id='logo-loader-container'>
      <div id="logo-loader-content">
        <img className={`${loaderSize} ${loaderColor}`} src={logoImg} alt='' />
        {text &&
          <h3>{text}</h3>
        }
      </div>
    </div>
  )
}

export default LogoLoader
