import React from 'react'

import './style.scss'

const RicetteSlider = ({ items }) => {
  return (
    <div id="ricette-slider">
    {items.map(([id, text, image, url]) => (
        <div className="ricetta-box" key={id}>
          <a href={url || '!#'} target='_blank' rel="noreferrer">
            <div className="mask"></div>
            <div className="image" style={{ backgroundImage: 'url(' + image + ')' }}></div>
            <p>{text.toUpperCase()}</p>
          </a>
        </div>
    ))}
    </div>
  )
}

export default RicetteSlider
