import React from 'react'
import i18n from '../i18n'
import { useTranslation, Trans } from 'react-i18next'
import { returnAbbr } from 'utils/Utils'

const getInputData = () => {
  const { t } = useTranslation('inputLots')
  const dataIta = {
    'c17ddcb7-a32a-47bb-a873-50454c9474f3': {
      id: 1,
      title: t('c17ddcb7-a32a-47bb-a873-50454c9474f3.title'),
      content: {
        type: 'A',
        header: require('../assets/img/traceability-page/input-lots/c17ddcb7-a32a-47bb-a873-50454c9474f3/header.jpg').default,
        titolo: t('c17ddcb7-a32a-47bb-a873-50454c9474f3.content.title'),
        testo: t('c17ddcb7-a32a-47bb-a873-50454c9474f3.content.text'),
        ingredienti: <Trans
                        t={t}
                        i18nKey='c17ddcb7-a32a-47bb-a873-50454c9474f3.content.ingredients'
                        components={{ 1: <div />, 2: <span /> }}
                      />,
        loghi: [
          require('../assets/img/traceability-page/input-lots/c17ddcb7-a32a-47bb-a873-50454c9474f3/logo1.jpg').default,
          require('../assets/img/traceability-page/input-lots/c17ddcb7-a32a-47bb-a873-50454c9474f3/logo2.png').default,
          require('../assets/img/traceability-page/input-lots/c17ddcb7-a32a-47bb-a873-50454c9474f3/logo3.png').default
        ]
      }
    },
    'a250a72e-46dd-4c73-a40d-bf7adf38dc06': {
      id: 2,
      title: t('a250a72e-46dd-4c73-a40d-bf7adf38dc06.title'),
      content: {
        type: 'A',
        header: require('../assets/img/traceability-page/input-lots/a250a72e-46dd-4c73-a40d-bf7adf38dc06/header.jpg').default,
        titolo: t('a250a72e-46dd-4c73-a40d-bf7adf38dc06.content.title'),
        testo: t('a250a72e-46dd-4c73-a40d-bf7adf38dc06.content.text'),
        ingredienti: <Trans
                        t={t}
                        i18nKey='a250a72e-46dd-4c73-a40d-bf7adf38dc06.content.ingredients'
                        components={{ 1: <span /> }}
                      />,
        loghi: [
          require('../assets/img/traceability-page/input-lots/a250a72e-46dd-4c73-a40d-bf7adf38dc06/logo1.jpg').default,
          require('../assets/img/traceability-page/input-lots/a250a72e-46dd-4c73-a40d-bf7adf38dc06/logo2.png').default,
          require('../assets/img/traceability-page/input-lots/a250a72e-46dd-4c73-a40d-bf7adf38dc06/logo3.png').default
        ]
      }
    },
    'dde43603-360a-4f0e-84c3-9d9e9fe597d0': {
      id: 3,
      title: t('dde43603-360a-4f0e-84c3-9d9e9fe597d0.title'),
      content: {
        type: 'A',
        header: require('../assets/img/traceability-page/input-lots/dde43603-360a-4f0e-84c3-9d9e9fe597d0/header.jpg').default,
        titolo: t('dde43603-360a-4f0e-84c3-9d9e9fe597d0.content.title'),
        testo: t('dde43603-360a-4f0e-84c3-9d9e9fe597d0.content.text'),
        ingredienti: <Trans
                        t={t}
                        i18nKey='dde43603-360a-4f0e-84c3-9d9e9fe597d0.content.ingredients'
                        components={{ 1: <div />, 2: <span /> }}
                      />,
        loghi: [
          require('../assets/img/traceability-page/input-lots/dde43603-360a-4f0e-84c3-9d9e9fe597d0/logo1.jpg').default,
          require('../assets/img/traceability-page/input-lots/dde43603-360a-4f0e-84c3-9d9e9fe597d0/logo2.png').default,
          require('../assets/img/traceability-page/input-lots/dde43603-360a-4f0e-84c3-9d9e9fe597d0/logo3.png').default
        ]
      }
    },
    'a36130e3-b92d-4674-8a33-4bcfd62077c0': {
      id: 4,
      title: t('a36130e3-b92d-4674-8a33-4bcfd62077c0.title'),
      content: {
        type: 'A',
        header: require('../assets/img/traceability-page/input-lots/a36130e3-b92d-4674-8a33-4bcfd62077c0/it/header.jpg').default,
        titolo: t('a36130e3-b92d-4674-8a33-4bcfd62077c0.content.title'),
        testo: t('a36130e3-b92d-4674-8a33-4bcfd62077c0.content.text'),
        ingredienti: <Trans
                        t={t}
                        i18nKey='a36130e3-b92d-4674-8a33-4bcfd62077c0.content.ingredients'
                        components={{ 1: <div />, 2: <span /> }}
                      />,
        loghi: [
          require('../assets/img/traceability-page/input-lots/a36130e3-b92d-4674-8a33-4bcfd62077c0/it/logo1.jpg').default,
          require('../assets/img/traceability-page/input-lots/a36130e3-b92d-4674-8a33-4bcfd62077c0/it/logo2.jpg').default,
          require('../assets/img/traceability-page/input-lots/a36130e3-b92d-4674-8a33-4bcfd62077c0/it/logo3.jpg').default
        ]
      }
    }
  }
  const dataEng = {
    'c17ddcb7-a32a-47bb-a873-50454c9474f3': {
      id: 1,
      title: t('c17ddcb7-a32a-47bb-a873-50454c9474f3.title'),
      content: {
        type: 'A',
        header: require('../assets/img/traceability-page/input-lots/c17ddcb7-a32a-47bb-a873-50454c9474f3/header.jpg').default,
        titolo: t('c17ddcb7-a32a-47bb-a873-50454c9474f3.content.title'),
        testo: t('c17ddcb7-a32a-47bb-a873-50454c9474f3.content.text'),
        ingredienti: <Trans
                        t={t}
                        i18nKey='c17ddcb7-a32a-47bb-a873-50454c9474f3.content.ingredients'
                        components={{ 1: <div />, 2: <span /> }}
                      />,
        loghi: [
          require('../assets/img/traceability-page/input-lots/c17ddcb7-a32a-47bb-a873-50454c9474f3/logo1.jpg').default,
          require('../assets/img/traceability-page/input-lots/c17ddcb7-a32a-47bb-a873-50454c9474f3/logo2.png').default,
          require('../assets/img/traceability-page/input-lots/c17ddcb7-a32a-47bb-a873-50454c9474f3/logo3.png').default
        ]
      }
    },
    'a250a72e-46dd-4c73-a40d-bf7adf38dc06': {
      id: 2,
      title: t('a250a72e-46dd-4c73-a40d-bf7adf38dc06.title'),
      content: {
        type: 'A',
        header: require('../assets/img/traceability-page/input-lots/a250a72e-46dd-4c73-a40d-bf7adf38dc06/header.jpg').default,
        titolo: t('a250a72e-46dd-4c73-a40d-bf7adf38dc06.content.title'),
        testo: t('a250a72e-46dd-4c73-a40d-bf7adf38dc06.content.text'),
        ingredienti: <Trans
                        t={t}
                        i18nKey='a250a72e-46dd-4c73-a40d-bf7adf38dc06.content.ingredients'
                        components={{ 1: <span /> }}
                      />,
        loghi: [
          require('../assets/img/traceability-page/input-lots/a250a72e-46dd-4c73-a40d-bf7adf38dc06/logo1.jpg').default,
          require('../assets/img/traceability-page/input-lots/a250a72e-46dd-4c73-a40d-bf7adf38dc06/logo2.png').default,
          require('../assets/img/traceability-page/input-lots/a250a72e-46dd-4c73-a40d-bf7adf38dc06/logo3.png').default
        ]
      }
    },
    'dde43603-360a-4f0e-84c3-9d9e9fe597d0': {
      id: 3,
      title: t('dde43603-360a-4f0e-84c3-9d9e9fe597d0.title'),
      content: {
        type: 'A',
        header: require('../assets/img/traceability-page/input-lots/dde43603-360a-4f0e-84c3-9d9e9fe597d0/header.jpg').default,
        titolo: t('dde43603-360a-4f0e-84c3-9d9e9fe597d0.content.title'),
        testo: t('dde43603-360a-4f0e-84c3-9d9e9fe597d0.content.text'),
        ingredienti: <Trans
                        t={t}
                        i18nKey='dde43603-360a-4f0e-84c3-9d9e9fe597d0.content.ingredients'
                        components={{ 1: <div />, 2: <span /> }}
                      />,
        loghi: [
          require('../assets/img/traceability-page/input-lots/dde43603-360a-4f0e-84c3-9d9e9fe597d0/logo1.jpg').default,
          require('../assets/img/traceability-page/input-lots/dde43603-360a-4f0e-84c3-9d9e9fe597d0/logo2.png').default,
          require('../assets/img/traceability-page/input-lots/dde43603-360a-4f0e-84c3-9d9e9fe597d0/logo3.png').default
        ]
      }
    },
    'a36130e3-b92d-4674-8a33-4bcfd62077c0': {
      id: 4,
      title: t('a36130e3-b92d-4674-8a33-4bcfd62077c0.title'),
      content: {
        type: 'A',
        header: require('../assets/img/traceability-page/input-lots/a36130e3-b92d-4674-8a33-4bcfd62077c0/en/header.jpg').default,
        titolo: t('a36130e3-b92d-4674-8a33-4bcfd62077c0.content.title'),
        testo: t('a36130e3-b92d-4674-8a33-4bcfd62077c0.content.text'),
        ingredienti: <Trans
                        t={t}
                        i18nKey='a36130e3-b92d-4674-8a33-4bcfd62077c0.content.ingredients'
                        components={{ 1: <div />, 2: <span /> }}
                      />,
        loghi: [
          require('../assets/img/traceability-page/input-lots/a36130e3-b92d-4674-8a33-4bcfd62077c0/en/logo1.jpg').default,
          require('../assets/img/traceability-page/input-lots/a36130e3-b92d-4674-8a33-4bcfd62077c0/en/logo2.jpg').default,
          require('../assets/img/traceability-page/input-lots/a36130e3-b92d-4674-8a33-4bcfd62077c0/en/logo3.jpg').default
        ]
      }
    }
    // '85fdb2e4-6c44-4995-9c2d-bc7b96a1c7eb': {
    //   id: 5,
    //   title: t('85fdb2e4-6c44-4995-9c2d-bc7b96a1c7eb.title'),
    //   content: {
    //     type: 'A',
    //     header: require('../assets/img/traceability-page/input-lots/85fdb2e4-6c44-4995-9c2d-bc7b96a1c7eb/header.jpg').default,
    //     titolo: t('85fdb2e4-6c44-4995-9c2d-bc7b96a1c7eb.content.title'),
    //     testo: t('85fdb2e4-6c44-4995-9c2d-bc7b96a1c7eb.content.text'),
    //     ingredienti: <Trans
    //                     t={t}
    //                     i18nKey='85fdb2e4-6c44-4995-9c2d-bc7b96a1c7eb.content.ingredients'
    //                     components={{ 1: <div />, 2: <span /> }}
    //                   />,
    //     loghi: [
    //       require('../assets/img/traceability-page/input-lots/85fdb2e4-6c44-4995-9c2d-bc7b96a1c7eb/logo1.PNG').default,
    //       require('../assets/img/traceability-page/input-lots/85fdb2e4-6c44-4995-9c2d-bc7b96a1c7eb/logo2.png').default,
    //       require('../assets/img/traceability-page/input-lots/85fdb2e4-6c44-4995-9c2d-bc7b96a1c7eb/logo3.jpg').default
    //     ]
    //   }
    // }
  }

  const lang = returnAbbr(i18n.language)
  const getProductImageLang = (lang) => {
    let data = {}
    switch (lang) {
      case 'en': {
        data = dataEng
        break
      }
      case 'it': {
        data = dataIta
        break
      }
      // case 'fr': {
      //   data = dataIta
      //   break
      // }
      default: {
        data = dataEng
        break
      }
    }
    return data
  }
  return getProductImageLang(lang)
}

export default getInputData
