import React from 'react'

import { useTranslation } from 'react-i18next'

import { Grid, Container } from '@material-ui/core'

import felsineoLogoImage from 'assets/img/felsineo-logo-b.png'

import contactAddressIcon from 'assets/img/company-page/contact/address.svg'
import contactEshopIcon from 'assets/img/company-page/contact/eshop.svg'
import contactFaxIcon from 'assets/img/company-page/contact/fax.svg'
import contactMailIcon from 'assets/img/company-page/contact/mail.svg'
import contactPivaIcon from 'assets/img/company-page/contact/piva.svg'
import contactTelephoneIcon from 'assets/img/company-page/contact/telephone.svg'
import contactWebsiteIcon from 'assets/img/company-page/contact/website.svg'

const CompanyPage = () => {
  const { t, i18n } = useTranslation('companyPage')

  const keyLanguage = i18n.language.split('-')[0]

  return (
    <div id="company-page">
      <section id="header"></section>

      <section className="rounded-page">
        <Container>

          <article id="azienda">
            <h1 className="title-page">{t('company.title')}</h1>
            <p>{t('company.text1')}</p>
            <p>{t('company.text2')}</p>
            <ul>
              <li>{t('company.item1')}</li>
              <li>{t('company.item2')}</li>
              <li>{t('company.item3')}</li>
            </ul>
          </article>

          <article id="numeri">
            <img src={felsineoLogoImage} alt="Felsineo FMV" />
            <ul>
              <li>
                <h1>75%</h1>
                <h3>{t('numbers.1')}</h3>
              </li>
              <li>
                <h1>1,5</h1>
                <h3>{t('numbers.2')}</h3>
              </li>
              <li>
                <h1>2.500 m²</h1>
                <h3>{t('numbers.3')}</h3>
              </li>
              <li>
                <h1>5</h1>
                <h3>{t('numbers.4')}</h3>
              </li>
              <li>
                <h1>18</h1>
                <h3>{t('numbers.5')}</h3>
              </li>
            </ul>
          </article>

          <article id="contact">
            <h1>FelsineoVeg S.r.l. - Società Benefit</h1>
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2845.7014215394047!2d11.231610199329364!3d44.50078444332314!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x477fd6e0ae5b9435%3A0xdae54f505d4e9c52!2sVia%20Masetti%2C%206%2C%2040069%20Zona%20Industriale%20BO!5e0!3m2!1sit!2sit!4v1627295038650!5m2!1sit!2sit" allowFullScreen={true} loading="lazy"></iframe>
            <Grid container id="detail" alignItems="center">
              <Grid item xs={3}><img src={contactAddressIcon} alt="" /></Grid>
              <Grid item xs={9}>Via C. Masetti, 6 - 40069<br />Zola Predosa (BO) - Italia</Grid>
              <Grid item xs={3}><img src={contactPivaIcon} alt="" /></Grid>
              <Grid item xs={9}>{t('contact.piva')} 03561781208</Grid>
              <Grid item xs={3}><img src={contactTelephoneIcon} alt="" /></Grid>
              <Grid item xs={9}>+39 051 0190601</Grid>
              <Grid item xs={3}><img src={contactFaxIcon} alt="" /></Grid>
              <Grid item xs={9}>+39 051 0190602</Grid>
              <Grid item xs={3}><img src={contactMailIcon} alt="" /></Grid>
              <Grid item xs={9}><a href="mailto: info@felsineoveg.com">info@felsineoveg.com</a></Grid>
              <Grid item xs={3}><img src={contactEshopIcon} alt="" /></Grid>
              <Grid item xs={9}><a href="https://shop.felsineoveg.com" target="_blank" rel="noreferrer">shop.felsineoveg.com</a></Grid>
              <Grid item xs={3}><img src={contactWebsiteIcon} alt="" /></Grid>
              <Grid item xs={9}>
                {keyLanguage === 'it'
                  ? <a href="http://www.felsineoveg.com/" target="_blank" rel="noreferrer">www.felsineoveg.com/</a>
                  : <a href="http://www.felsineoveg.com/en/" target="_blank" rel="noreferrer">www.felsineoveg.com/en/</a>
                }
              </Grid>
            </Grid>
          </article>

        </Container>
      </section>
    </div>
  )
}

export default CompanyPage
