import React, { useEffect, useRef } from 'react'

import { useTranslation } from 'react-i18next'
import { useSwipeable } from 'react-swipeable'

import { Paper, Link } from '@material-ui/core'
import Carousel from 'react-material-ui-carousel'

import arrowIcon from 'assets/img/icons/rarrow-white.svg'

import './style.scss'

const DettaglioFaseSlider = (props) => {
  const { items, setIsOpen, setSelectedDettaglio } = props
  const handlers = useSwipeable({
    onSwiping: () => { swipping.current = true },
    onSwiped: () => { swipping.current = false }
  })
  const swipping = useRef(null)

  const { t } = useTranslation('dettaglioFaseSlider')

  const handleClick = (e, item) => {
    e.preventDefault()
    setSelectedDettaglio(item)
    setIsOpen(true)
  }

  const handleScroll = (e) => {
    if (swipping.current) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }
    return true
  }

  useEffect(() => {
    window.addEventListener('touchmove', handleScroll, { passive: false })
    return () => window.removeEventListener('touchmove', handleScroll, { passive: false })
  }, [])

  return (
    <div className="dettaglio-fase" {...handlers}>
      <Carousel
        autoPlay={false}
        animation='slide'
        indicatorIconButtonProps={{
          style: { color: '#000' }
        }}
        activeIndicatorIconButtonProps={{
          style: { color: '#B8BF23' }
        }}
        navButtonsAlwaysInvisible={true}
        indicators={items.length > 1}
      >
        {items.map(item => (
          <Paper key={item.id} elevation={0} square>
              <Link onClick={(e) => handleClick(e, item.content)} style={{ cursor: 'pointer' }}>
                <div className="container">
                  <img src={item.cover} alt="" />
                  <div className="text-container">
                    <div className="text">
                      <h1>{item.title}</h1>
                      <p>{t('button')} <img src={arrowIcon} alt="" /></p>
                    </div>
                  </div>
                </div>
              </Link>
          </Paper>
        ))}
      </Carousel>
    </div>
  )
}

export default DettaglioFaseSlider
