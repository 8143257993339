require('dotenv').config()
const manifest = require('../package.json')
const configuration = {
  logTag: process.env.REACT_APP_APIO_LOG || null,
  version: manifest.version,
  apiBaseUrl: process.env.REACT_APP_API_BASE_URL || 'https://testapi.trusty.id',
  environment: process.env.REACT_APP_ENV,
  token: process.env.REACT_APP_API_TOKEN,
  blockchainExplorer: 'https://www.blockchain.com',
  companyId: '7513b537-5a0b-45d2-a33c-3a9554be532f'
}

export default configuration
