import { useTranslation } from 'react-i18next'

import img1 from 'assets/img/certifications-page/1.png'
import img2 from 'assets/img/certifications-page/2.png'
import img3 from 'assets/img/certifications-page/3.png'
import img4 from 'assets/img/certifications-page/4.png'
import img5 from 'assets/img/certifications-page/5.png'
import img6 from 'assets/img/certifications-page/6.png'
import img7 from 'assets/img/certifications-page/7.png'
import img8 from 'assets/img/certifications-page/8.png'
import img9 from 'assets/img/certifications-page/9.png'
import img10 from 'assets/img/certifications-page/10.png'

export default () => {
  const { t } = useTranslation('certificationPage')

  return [
    {
      id: 1,
      logo: img1,
      link: {
        title: t('certifications.data.1'),
        url: 'https://storage.googleapis.com/trusty-media-bucket/products/5ecedb88-143b-48b0-a3bf-11fcfb944567/9128c091-39fa-4d65-85ba-48440279d519.pdf'
      }
    },
    {
      id: 2,
      logo: img2,
      link: {
        title: t('certifications.data.2'),
        url: 'https://storage.googleapis.com/trusty-media-bucket/products/5ecedb88-143b-48b0-a3bf-11fcfb944567/cd57b195-6a87-4591-86eb-52152846860b.pdf'
      }
    },
    {
      id: 3,
      logo: img3,
      link: {
        title: t('certifications.data.3'),
        url: 'https://storage.googleapis.com/trusty-media-bucket/products/5ecedb88-143b-48b0-a3bf-11fcfb944567/03f2c7ae-fe3c-45b2-a1a7-7928ef8e644f.pdf'
      }
    },
    {
      id: 4,
      logo: img4,
      link: {
        title: t('certifications.data.4'),
        url: 'https://storage.googleapis.com/trusty-media-bucket/products/5ecedb88-143b-48b0-a3bf-11fcfb944567/62b3446f-c1af-43bc-a36b-7a5d849b2613.pdf'
      }
    },
    {
      id: 5,
      logo: img5,
      link: {
        title: t('certifications.data.5'),
        url: 'https://storage.googleapis.com/trusty-media-bucket/products/5ecedb88-143b-48b0-a3bf-11fcfb944567/7cd835be-8548-47d9-8cc5-149901cb20a6.pdf'
      }
    },
    {
      id: 6,
      logo: img6,
      link: {
        title: t('certifications.data.6'),
        url: 'https://storage.googleapis.com/trusty-media-bucket/products/5ecedb88-143b-48b0-a3bf-11fcfb944567/420954a8-8a38-45bb-bb61-7c4919060486.pdf'
      }
    },
    {
      id: 7,
      logo: img7,
      link: {
        title: t('certifications.data.7'),
        url: 'https://storage.googleapis.com/trusty-media-bucket/products/5ecedb88-143b-48b0-a3bf-11fcfb944567/2d405a19-648f-4408-998e-a70b818a3926.pdf'
      }
    },
    {
      id: 8,
      logo: img8,
      link: {
        title: t('certifications.data.8'),
        url: 'https://storage.googleapis.com/trusty-media-bucket/products/5ecedb88-143b-48b0-a3bf-11fcfb944567/9e27ed22-e8c5-45b9-b385-483fa7de54da.pdf'
      }
    },
    {
      id: 9,
      logo: img9,
      link: {
        title: t('certifications.data.9'),
        url: 'https://storage.googleapis.com/trusty-media-bucket/products/5ecedb88-143b-48b0-a3bf-11fcfb944567/2976762c-d7fb-4471-b26b-98a5063f2a5c.pdf'
      }
    },
    {
      id: 10,
      logo: img10,
      link: {
        title: t('certifications.data.10'),
        url: 'https://storage.googleapis.com/trusty-media-bucket/products/5ecedb88-143b-48b0-a3bf-11fcfb944567/1ad105dc-5e3d-4493-96ce-fcce2c5b6fdc.pdf'
      }
    }
  ]
}
