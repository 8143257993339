import { useTranslation } from 'react-i18next'

import header0 from 'assets/img/traceability-page/header-fasi/0.jpg'
import header1 from 'assets/img/traceability-page/header-fasi/1.jpg'
import header2 from 'assets/img/traceability-page/header-fasi/2.jpg'
import header3 from 'assets/img/traceability-page/header-fasi/3.jpg'
import header4 from 'assets/img/traceability-page/header-fasi/4.jpg'
import header5 from 'assets/img/traceability-page/header-fasi/5.jpg'

import dettaglioCover2 from 'assets/img/traceability-page/cover-fasi/2.png'
import dettaglioCover3 from 'assets/img/traceability-page/cover-fasi/3.png'
import dettaglioCover4 from 'assets/img/traceability-page/cover-fasi/4.png'
import dettaglioCover5 from 'assets/img/traceability-page/cover-fasi/5.png'
import dettaglioCover6 from 'assets/img/traceability-page/cover-fasi/6.png'

import dettaglioHeader2 from 'assets/img/traceability-page/header-fasi/02.jpg'

export default () => {
  const { t } = useTranslation('traceabilityCard')

  return {
    traceability: [
      {
        id: 0,
        title: t('cards.0.title'),
        header: header0,
        dettaglio: []
      },
      {
        id: 1,
        title: t('cards.1.title'),
        header: header1,
        dettaglio: [
          {
            id: 1,
            title: t('cards.1.detail.1.title'),
            cover: dettaglioCover2,
            content: {
              type: 'B',
              header: dettaglioHeader2,
              titolo: t('cards.1.detail.1.content.title'),
              testo1: t('cards.1.detail.1.content.text1'),
              testo2: t('cards.1.detail.1.content.text2'),
              testo3: t('cards.1.detail.1.content.text3')
            }
          }
        ]
      },
      {
        id: 2,
        title: t('cards.2.title'),
        header: header2,
        dettaglio: {
          circleCover: dettaglioCover3
        }
      },
      {
        id: 3,
        title: t('cards.3.title'),
        header: header3,
        dettaglio: {
          circleCover: dettaglioCover4
        }
      },
      {
        id: 4,
        title: t('cards.4.title'),
        header: header4,
        dettaglio: {
          circleCover: dettaglioCover5
        }
      },
      {
        id: 5,
        title: t('cards.5.title'),
        header: header5,
        dettaglio: {
          circleCover: dettaglioCover6
        }
      }
    ]
  }
}
