import React, { useState } from 'react'
import { Controller } from 'react-scrollmagic'

import { useTranslation, Trans } from 'react-i18next'

import TraceabilityCard from 'components/TraceabilityCard/TraceabilityCard'

import { Container, Grid } from '@material-ui/core'

import useNavigation from 'hooks/useNavigation'

import blockchainImage from 'assets/img/traceability-page/blockchain.svg'
import lastPageHeader from 'assets/img/traceability-page/last-page/header.png'
import lastLogo1 from 'assets/img/traceability-page/last-page/1.svg'
import lastLogo2 from 'assets/img/traceability-page/last-page/2.svg'
import lastLogo3 from 'assets/img/traceability-page/last-page/3.svg'

import BlockchainModal from './BlockchainModal'

const TraceabilityContent = ({ lot }) => {
  const { t } = useTranslation('traceabilityPage')
  const { changeSection } = useNavigation()

  const [isOpenModal, setIsOpenModal] = useState(false)

  const handleClick = () => {
    setIsOpenModal(true)
  }

  const handleIspirato = (e) => {
    e.preventDefault()
    changeSection(1, () => {
      document.getElementById('ricette').scrollIntoView()
    })
  }

  return <>
    <BlockchainModal
      isOpenModal={isOpenModal}
      setIsOpenModal={setIsOpenModal}
    />
    <section id="first-page">
      <Container>
        <h2 className='primary-color'>{t('firstPage.title')}</h2>
        <div className='center-text'>
          <video controls controlsList="nodownload" disablePictureInPicture>
            <source src="https://storage.googleapis.com/trusty-media-bucket/products/5ecedb88-143b-48b0-a3bf-11fcfb944567/3a172a55-325a-4eb2-a494-2cb62a98021a.mp4"
                      type="video/mp4" />
            Sorry, your browser doesn&apos;t support embedded videos.
          </video>
        </div>
        <p>{t('firstPage.text')}</p>
      </Container>
    </section>
    <section id="cards">
      <Controller>
        <>
          {lot.traceability.map(fase => <TraceabilityCard key={fase.id} card={fase} />)}
        </>
      </Controller>
    </section>
    <section id="blockchain">
      <Container>
        <Grid container spacing={3} alignItems='center'>
          <Grid item xs={3}>
            <img src={blockchainImage} alt='Blockchain' />
          </Grid>
          <Grid item xs={9}>
            <p>
              <Trans
                t={t}
                i18nKey='blockchain.text'
                components={{ 1: <span /> }}
              />
            </p>
          </Grid>
        </Grid>
        <div className='verifica'>
          <button className='custom-button' onClick={handleClick}>{t('blockchain.button')}</button>
        </div>
      </Container>
    </section>
    <section id="last-page">
      <img src={lastPageHeader} alt='' />
      <Container>
        <h3>{t('lastPage.text')}</h3>
        <ul>
          <li>
            <img src={lastLogo1} alt='' />
            <div>{t('lastPage.list.1')}</div>
          </li>
          <li>
            <Container>
              <img src={lastLogo2} alt='' />
              <div>{t('lastPage.list.2')}</div>
            </Container>
          </li>
          <li>
            <img src={lastLogo3} alt='' />
            <div>{t('lastPage.list.3')}</div>
          </li>
        </ul>
        <p>{t('lastPage.inspiration.text')}</p>
        <a href="#" onClick={handleIspirato}>{t('lastPage.inspiration.callToAction')}</a>
      </Container>
    </section>
  </>
}

export default TraceabilityContent
