import React from 'react'

import { useTranslation, Trans } from 'react-i18next'
import useLotData from 'hooks/useLotData'

import { Container, Grid, Typography } from '@material-ui/core'
import CustomModal from 'components/CustomModal/CustomModal'

import headerModalImage from 'assets/img/traceability-page/loghi-modal-blockchain/header.png'
import logo1 from 'assets/img/traceability-page/loghi-modal-blockchain/1.svg'
import logo2 from 'assets/img/traceability-page/loghi-modal-blockchain/2.svg'
import logo3 from 'assets/img/traceability-page/loghi-modal-blockchain/3.svg'
import logoA from 'assets/img/traceability-page/loghi-modal-blockchain/a.png'
import logoB from 'assets/img/traceability-page/loghi-modal-blockchain/b.png'
import logoC from 'assets/img/traceability-page/loghi-modal-blockchain/c.png'

import { timestampToDate } from 'utils/Utils'

const BlockchainModal = ({ isOpenModal, setIsOpenModal }) => {
  const { lot } = useLotData()
  const { t } = useTranslation('blockchainModal')

  const opentimestamps = lot.opentimestamps[lot.opentimestamps.length - 1]

  const date = timestampToDate(opentimestamps?.timestamp)
  const hash = opentimestamps?.transactionId

  return (
    <CustomModal
      isOpen={isOpenModal}
      setIsOpen={setIsOpenModal}
      className='blockchain-modal'
      closeButton
    >
      <section id="header">
        <h1>{t('title')}</h1>
        <img src={headerModalImage} alt='' />
      </section>
      <section className="rounded-page">
        <Container>
          <p id="main-content">
            <Trans
              t={t}
              i18nKey='text'
              components={{ 1: <b />, 2: <em /> }}
            />
          </p>
          <h1>{t('lotNumber')} {lot.number}</h1>

          <Grid container id="detail" spacing={1}>
            <Grid item xs={2}></Grid>
            <Grid item xs={10}>{t('status.title')}</Grid>
            {opentimestamps?.verified
              ? <>
                  <Grid item xs={2}><img src={logo1} /></Grid>
                  <Grid item xs={10}>{t('status.markedLot')}</Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10}>{t('status.data')}</Grid>
                  <Grid item xs={2}><img src={logo2} /></Grid>
                  <Grid item xs={10}>{date || '-'}</Grid>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10}>{t('status.hash')}</Grid>
                  <Grid item xs={2}><img src={logo3} /></Grid>
                  <Grid item xs={10} zeroMinWidth>
                    <Typography noWrap>{hash || '-'}</Typography>
                  </Grid>
                </>
              : <>
                  <Grid item xs={2}></Grid>
                  <Grid item xs={10}>
                    {t('status.pendingLot')}
                  </Grid>
                </>
            }
          </Grid>

          <Grid container id="download">
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={12}>
                  <a href={opentimestamps?.filename} target='_blank' rel="noreferrer">
                    <img src={logoA} />
                  </a>
                </Grid>
                <Grid item xs={12}>
                  <div>PDF</div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={12}>
                  <a href={opentimestamps?.ots} target='_blank' rel="noreferrer">
                    <img src={logoB} />
                  </a>
                </Grid>
                <Grid item xs={12}>
                  <div>OTS</div>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Grid container>
                <Grid item xs={12}>
                  <a href={'https://discover.trusty.id/opentimestamps/?filename=' + opentimestamps?.ots + '&lotNum=' + lot.number} target='_blank' rel="noreferrer">
                    <img src={logoC} />
                  </a>
                </Grid>
                <Grid item xs={12}>
                  <div>INFO</div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Container>
      </section>
    </CustomModal>
  )
}
export default BlockchainModal
