import React, { createContext, useState, useEffect } from 'react'

import { useTranslation, Trans } from 'react-i18next'

import { getData } from 'actions/AppActions'

import getLotStatic from 'data/lot'
import getInputDataStatic from 'data/inputData'

import mapPlaceholder from 'assets/img/traceability-page/map-placeholder.png'

import { alog } from 'utils/apioLog'
import { formatDate } from 'utils/Utils'

const LotContext = createContext()

export const LotProvider = ({ children }) => {
  const [withLot, setWithLot] = useState(false)
  const [lot, setLot] = useState([])
  const [lotNotFound, setLotNotFound] = useState(false)
  const [lotLoaded, setLotLoaded] = useState(false)

  const { t } = useTranslation('traceabilityCard')

  const lotStatic = getLotStatic()
  const inputDataStatic = getInputDataStatic()

  useEffect(() => (lot?.traceability?.length) && setLotLoaded(true), [lot])

  const makeLot = async (gtin, lotId) => {
    try {
      alog('Data', 'fetching', 'DEBUG', 'info')
      const data = await getData(gtin, lotId)
      alog('Data', 'fetched', 'DEBUG', 'ok')

      alog('Lot', 'making', 'DEBUG', 'info')
      const mergedLot = mergeLots(data, lotStatic)
      setLot(mergedLot)
      alog('Lot', 'made', 'DEBUG', 'ok')
    } catch (err) {
      setLotNotFound(true)
      alog('Error: ', err, 'ERROR', 'error')
    }
  }

  const mergeLots = (lot, lotStatic) => {
    if (lot.traceability.length !== lotStatic.traceability.length) {
      throw new Error('Inconsistenza tra i dati statici e quelli in piattaforma')
    }

    const res = {
      ...lotStatic,
      number: lot.lotNumber,
      opentimestamps: lot.opentimestamps
    }

    // Text and shortText
    res.traceability.forEach((fase, i) => {
      const location = lot.traceability[i].location
      const address = location ? `${location.street} - ${location.postalCode} ${location.city} ${location.state}` : ''

      fase.text = <Trans
              t={t}
              i18nKey={'cards.' + i + '.text'}
              values={{
                data: formatDate(lot.traceability[i].data),
                luogo: address
              }}
              components={{ 1: <em /> }}
            />

      fase.shortText = <Trans
              t={t}
              i18nKey={'cards.' + i + '.shortText'}
              values={{
                data: formatDate(lot.traceability[i].data),
                luogo: address
              }}
              components={{ 1: <em /> }}
            />
    })

    // First fase
    lot.traceability[0].inputs.forEach(input => {
      const key = input.productId

      if (Object.keys(inputDataStatic).includes(key)) {
        const dettaglio = { ...inputDataStatic[key] }
        dettaglio.content.distributore = input.locationFrom.name
        dettaglio.content.data = formatDate(input.data)
        dettaglio.cover = input.map || mapPlaceholder
        res.traceability[0].dettaglio.push(dettaglio)
      }
    })

    return res
  }

  return <LotContext.Provider value={{
    withLot,
    setWithLot,
    makeLot,
    lot,
    lotNotFound,
    lotLoaded,
    setLotNotFound
  }}>
    {children}
  </LotContext.Provider>
}

export default LotContext
