import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'

import MetaTags from 'react-meta-tags'

import { useTranslation } from 'react-i18next'
import { getProduct } from 'actions/AppActions'

import useNavigation from 'hooks/useNavigation'
import useLotData from 'hooks/useLotData'

import Menu from 'components/Menu/Menu'
import NotFound from 'components/NotFound/NotFound'
import LogoLoader from 'components/LogoLoader/LogoLoader'
import SocialBar from 'components/SocialBar/SocialBar'

import ProductPage from 'views/ProductPage/ProductPage'
import CompanyPage from 'views/CompanyPage/CompanyPage'
import CertificationsPage from 'views/CertificationsPage/CertificationsPage'
import TraceabilityPage from 'views/TraceabilityPage/TraceabilityPage'

import { alog } from 'utils/apioLog'
import configuration from 'configuration'

import 'assets/scss/style.scss'

const RootPage = () => {
  const [productFound, setProductFound] = useState(true)
  const [productLoaded, setProductLoaded] = useState(false)

  const { sectionActive, callback, changeSection } = useNavigation()
  const { lot, withLot, lotLoaded, makeLot, setWithLot } = useLotData()

  const { gtin, lotId } = useParams()

  const { t } = useTranslation('common')

  // Check product's companyID
  useEffect(() => {
    alog('Product', 'checking', 'DEBUG', 'info')
    getProduct(gtin, ['companyId'])
      .then(res => {
        if (res?.companyId === configuration.companyId) {
          alog('Product', 'valid', 'DEBUG', 'ok')
          setProductLoaded(true)
        } else {
          throw new Error('Error: Ivalid gtin!')
        }
      })
      .catch((err) => {
        setProductFound(false)
        alog('Error: ', err, 'ERROR', 'error')
      })
  }, [])

  useEffect(() => {
    setWithLot(Boolean(lotId))
  }, [lotId])

  useEffect(() => {
    if (productLoaded && productFound && withLot && !lotLoaded) {
      changeSection(4)
      makeLot(gtin, lotId)
    }
  }, [withLot, lotLoaded, productLoaded, productFound])

  useEffect(() => {
    if (callback) {
      callback.consume()
    }
  }, [sectionActive])

  const sections = {
    1: <ProductPage />,
    2: <CompanyPage />,
    3: <CertificationsPage />,
    4: <TraceabilityPage />
  }

  if (!productFound) {
    return <NotFound product />
  }

  if (!productLoaded) {
    return <LogoLoader size='large' />
  }

  return <>
    <MetaTags>
      <title>{t('titlePage')} | FelsineoVeg S.r.l.{lot.number && <> | Lot {lot.number}</>}</title>
    </MetaTags>

    <Menu />

    {sections[sectionActive]}

    <SocialBar />
  </>
}
export default RootPage
