export const timestampToDate = (timestamp) => {
  return new Date(timestamp).toLocaleDateString('it-IT')
}

export const formatDate = (date) => {
  return new Date(date).toLocaleDateString('it-IT')
}

export function returnAbbr (lang) {
  let abbr = 'it'
  if (lang.includes('en')) {
    abbr = 'en'
  } else if (lang.includes('fr')) {
    abbr = 'fr'
  }
  return abbr
}
