import React from 'react'

import logoFacebook from 'assets/img/loghi-social/facebook.svg'
import logoInstagram from 'assets/img/loghi-social/instagram.svg'
import logoLinkedin from 'assets/img/loghi-social/linkedin.svg'
import logoYoutube from 'assets/img/loghi-social/youtube.svg'

import './style.css'

const SocialBar = () => {
  return <div id="social-bar">
    <a href="https://www.facebook.com/veghiamo/" target='blank'><img src={logoFacebook} alt='Facebook' /></a>
    <a href="https://www.instagram.com/felsineoveg/" target='blank'><img src={logoInstagram} alt='Instagram' /></a>
    <a href="https://www.youtube.com/channel/UC3EPfmM9ig3OCwG3U7RfNWQ/" target='blank'><img src={logoYoutube} alt='Youtube' /></a>
    <a href="https://it.linkedin.com/company/gruppo-felsineo" target='blank'><img src={logoLinkedin} alt='Linkedin' /></a>
  </div>
}

export default SocialBar
