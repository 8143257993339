import React from 'react'
import { isMobileOnly } from 'react-device-detect'

import useScreenOrientation from 'hooks/useScreenOrientation'
import useLotData from 'hooks/useLotData'

import { Container } from '@material-ui/core'

import LogoLoader from 'components/LogoLoader/LogoLoader'
import NotFound from 'components/NotFound/NotFound'
import FindLot from 'components/FindLot/FindLot'
import RotationError from 'components/RotationError/RotationError'

import prodottoImage from 'assets/img/traceability-page/prodotto.png'

import TraceabilityContent from './TraceabilityContent'

const TraceabilityPage = () => {
  const { lotNotFound, lotLoaded, lot, withLot } = useLotData()
  const { isLandscape } = useScreenOrientation()

  if (withLot) {
    if (lotNotFound) {
      return <NotFound lot />
    }
    if (!lotLoaded) {
      return <LogoLoader size='large' />
    }
    if (isMobileOnly && isLandscape) {
      return <RotationError />
    }
  }

  return (
    <div id="traceability-page">
      <section id="header">
        <div id="logo-circle">
          <div id="circle">
            <img src={prodottoImage} alt="" />
          </div>
        </div>
      </section>
      <section className="rounded-page">
        {withLot
          ? <TraceabilityContent lot={lot} />
          : <Container>
              <FindLot />
            </Container>
        }
      </section>
    </div>
  )
}

export default TraceabilityPage
