import React from 'react'

import { Dialog, Fab } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'

import './style.css'

const CustomModal = (props) => {
  const { isOpen, setIsOpen, closeButton, children, ...other } = props

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <Dialog
      id="custom-modal"
      open={isOpen}
      onClose={handleClose}
      scroll="paper"
      fullScreen
      PaperProps={{
        elevation: 0,
        square: true,
        style: { margin: 0 }
      }}
      {...other}
    >

      {children}

      {closeButton && (
        <>
          <div style={{ padding: '15vw 0' }}></div>
          <Fab className="close-button" color="primary" onClick={handleClose} aria-label="close">
            <CloseIcon />
          </Fab>
        </>
      )}

    </Dialog>
  )
}

export default CustomModal
